<template>
  <div>
    <div class="col-12 mx-3">
      <h3>Klient indywidualny</h3>
    </div>

    <div v-if="showSuccess" class="alert alert-success" role="alert">
    Klient został dodany
    </div>
    <div v-if="showFail" class="alert alert-danger" role="alert">
     Nie ma nazwy klienta.
    </div>

    <form class="add-form bg-light border" @submit.prevent>
      <input
        v-model="name"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Imię i nazwisko"
      />

      <input
        v-model="email"
        type="text"
        class="form-control mb-1"
        placeholder="Email"
      />

      <input
        v-model="phoneNumber"
        type="text"
        class="form-control mt-1 mb-1"
        placeholder="Numer Telefonu"
      />
      <input
        v-model="address"
        type="text"
        class="form-control mb-1"
        placeholder="Adres"
      />
      <textarea
        v-model="notice"
        type="text"
        class="form-control mb-1"
        placeholder="Uwagi"
      />

      <button
        style="width: 100%; height: 65px"
        class="btn btn-info btn-sm mt-1"
        @click="addContractor()"
      >
        Dodaj klienta
      </button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      email: "",
      phoneNumber: "",
      address: "",
      notice: "",
      type: "customer",
      showSuccess: false,
      showFail: false,
    };
  },
  methods: {
    addContractor() {
      if (this.name == "") {
        this.showFail = true;
         setTimeout(() => {
          this.showFail = false;
        }, 2000);
      } else {
        this.$store
          .dispatch("createCustomer", {
            name: this.name,
            email: this.email,
            phoneNumber: this.phoneNumber,
            address: this.address,
            notice: this.notice,
            type: "customer",
          })
          .then((this.showSuccess = true));
        (this.name = ""),
          (this.email = ""),
          (this.phoneNumber = ""),
          (this.address = ""),
          (this.notice = "");
        setTimeout(() => {
          this.showSuccess = false;
        }, 2000);
      }
    },
  },
};
</script>
<style>
.add-form {
  width: 100%;
  max-width: 33vw;
  padding: 15px;
  margin: 0 auto;
}
</style>